
//import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';



const theme=createTheme({
    palette:{
        primary:{
            main:"#5BB033",
            contrastText: '#fff'
        },
        blackG:{
          //main:"#212121",
          main:"#3B3C43"
          //contrastText: '#fff'
        }
    },
    overrides: {
        // Sobrescribir estilos globales para los elementos que ganen foco
        MuiButtonBase: {
          // Se aplica a todos los elementos que extienden de ButtonBase
          root: {
            '&:focus': {
              outline: 'none',
            },
          },
        },
    },
    
})

export default theme