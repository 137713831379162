import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../context/UserProvider';
import { db } from '../../../config/firebase';
import {
  Button,
  Divider,
  Grid,
  Tab,
  Tabs,
  makeStyles,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { ManualIrrigation } from './ManualIrrigation';
import { ConfirmationAlerts } from './components/ConfirmationAlerts';
import { IrrigationContext } from '../../../context/IrrigationContext/IrrigationContext';
import { ConfigPumpsWater } from './components/ConfigPumpsWater';
import { AutomaticIrrigation } from './AutomaticIrrigation';
import { Cached } from '@material-ui/icons';



const IrrigationForm = () => {
  const { usuario,names,currentMac,canIdIrrigation}=useContext(UserContext);
  const { setCompleteManual,setProgressFlag,setRemoveFlag,setDisableAutoButton,setAutoFlagSave } = useContext(IrrigationContext)
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [typeAlert, setTypeAlert] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("")
  //const [progressFlag, setProgressFlag] = useState(false);
  //const [completeManual, setCompleteManual] = useState(false)
  //const [names, setNames] = useState([]); 

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  }; 

  

  const handleAlert = (isOpen, kindAlert) => {
    setTypeAlert(kindAlert)
    if(kindAlert === "info") {
      setAlertTitle("Info")
      setAlertMessage("Espere a que se configure correctamente el modulo")
    } else if(kindAlert === "success") {
      setAlertTitle("Configuración Exitosa")
      setAlertMessage("El modulo recibio el mensaje")
    }
    setOpen(isOpen)
  }

   // Función para guardar constantes en una ruta específica
   const clearConfigOk = (path, items) => {
    db.doc(path).update(items)
    .then(() => {
        //console.log("OK");
    })
    .catch(error => {
        console.error("Error al actualizar recOk:", error);
    });
}

  useEffect(() => {
    try{
      if(currentMac !== ""){
        const addr = usuario.username+'/infoDevices/'+currentMac+'/'+canIdIrrigation+'/fromModule/configOK';
        const unsubscribe = db.doc(addr).onSnapshot(snapshot => {
          const data = snapshot.data();
          if(data){
            if (data.accion === 246 && data.act === 'recOK' && data.kind === 0) {
              handleAlert(true,"success")
              setProgressFlag(valorAnterior => valorAnterior ? false : true)
              setCompleteManual(false) 
              const items = {
                accion: 0,
                act: "-"
              }
              clearConfigOk(addr,items)           
            } else if (data.accion === 246 && data.act === 'recOK' && data.kind === 1) {
              setCompleteManual(true)
              setProgressFlag(false)
              //console.log("Esto es completeManual:",completeManual)
              const items = {
                accion: 0,
                act: "-"
              }
              clearConfigOk(addr,items)           
            } else if(data.accion === 245 && data.act === 'recOK' && data.kind === 0) {
              handleAlert(true,"success")
              setAutoFlagSave(true);
              setDisableAutoButton(false);
              setRemoveFlag(false);
              const items = {
                accion: 0,
                act: "-"
              }
              clearConfigOk(addr,items)
            }
          }
          
        })
        // Limpiar el listener al desmontar el componente
        return () => unsubscribe();
      }
      
    } catch(error) {
      console.error("Error al detectar la respuesta del embebido:",error)
    }
  }, [canIdIrrigation,currentMac,usuario.username])

  useEffect(() => {
    if (open && typeAlert === "success") {
      const timer = setTimeout(() => {
        setOpen(false);
      }, 3000); // Ocultar el alerta después de 2 segundos

      return () => {
        clearTimeout(timer);
      };
    }
  }, [open,typeAlert]);
  


  return (
    <>

      <div>
        <Grid 
        container 
        justifyContent="center"
        alignItems="center"
        >
          <h2>Rutina de Riego</h2>
        </Grid>
      </div>

      <div style={{ marginTop: '30px',marginBottom: '15px' }}>
        <Grid
        container 
        justifyContent="center"
        alignItems="center"
        > 
          <Tabs 
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}            
          >
            <Tab label="Riego Manual" />
            <Tab label="Riego Automático" />    
          </Tabs>

        </Grid>
        
      </div>

      <ConfigPumpsWater />
      <Divider />

      <div hidden={tabValue !== 0}>
        <ManualIrrigation 
        canId={canIdIrrigation} 
        macId={currentMac} 
        arrayNames={names} 
        handleAlert={handleAlert} 
        //progressBar={progressFlag} 
        />
      </div>

      <div hidden={tabValue !== 1}>
        <AutomaticIrrigation  
        canId={canIdIrrigation} 
        macId={currentMac} 
        handleAlert={handleAlert}
        />
      </div>
    
      <div>  
      
      </div>

      <div>
        <ConfirmationAlerts 
        open={open} 
        setOpen={setOpen} 
        typeAlert={typeAlert} 
        message={alertMessage} 
        alertTitle={alertTitle}
        />
      </div>
    
    </>
  );
};

export default withRouter(IrrigationForm);
export{IrrigationForm}