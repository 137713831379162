import React, { useContext } from 'react'
import {db} from '../../../../config/firebase'
import InputNumber from './ConfigComponents/InputNumber';
import TresSelect from './ConfigComponents/TresSelect';
import SelectType from './ConfigComponents/SelectType';
import ConfigAlways from './ConfigOutACComponents/ConfigAlways';
import ConfigLeds1 from './ConfigOutACComponents/ConfigLeds1';
import ConfigHumni from './ConfigOutACComponents/ConfigHumni';

import {TogglesDataDefault} from '../../../../constants/ConfigOutDefault'

import ConfigFan from './ConfigOutACComponents/ConfigFan';
import ConfigExtractor from './ConfigOutACComponents/ConfigExtractor';
import ConfigPumbAC from './ConfigOutACComponents/ConfigPumbAC';

import {UserContext} from '../../../../context/UserProvider'

import moment from 'moment'
import 'moment/locale/es'
import UpdatingButton from './ConfigComponents/UpdatingButton';
import DialogConfirm from './ConfigComponents/DialogConfirm';
import TransitionAlerts from '../../../Common/TransitionAlerts';
import {backGroundList} from '../../../../constants/globalConst'
import { CurretOffsets } from './ConfigOutACComponents/CurretOffsets';

const actionLink="sendConfig"
const codifyRules=(comp)=>{
    const dataMqtt=[comp.canid,comp.outid,comp.type,comp.fallback,comp.ifCurrent,comp.actionCurrent,...comp.data1,...comp.data2,...comp.data3,comp.horaInicio,comp.cuantasHoras];
    const actionMqtt=0;
    const MSarray=[dataMqtt.length,actionMqtt,...dataMqtt];
    let MS=MSarray[0]+'';
    for (let i = 1; i < MSarray.length; i++) {
        //array[i]=parseInt(MSarray[i]);
        MS=MS+','+MSarray[i];
        }
    return MS;
}

const defaultOpenAlert={open:null,
    type:null,
    tittle:null,
    txt:null}

const ConfigOutAC = (propiedades) => {
    const props=propiedades.data;
    const ide=props.ide;
    const { updateNames } = useContext(UserContext);
    
    const [uid,setUid]=React.useState("");

    const [comp,setComp]=React.useState(null)

    const [error,setError]=React.useState(null);
    const [desactivar,setDesactivar]=React.useState(false);
    const [color,setColor]=React.useState("Green")
    const [color2,setColor2]=React.useState("Blue")
    const [nodeNameCache,setNodeNameCache]=React.useState()
    const {usuario}=React.useContext(UserContext)

    const [loading,setLoading]=React.useState(null)//Loading es para cargar , esto muestra el ciruclo cargando
    const [openWindow, setOpenWindow] = React.useState(false);

    const [openAlert,setOpenAlert]=React.useState(defaultOpenAlert)//Para abrir la Alerta de notificaciones
    const [dateSnap,setDateSnap]=React.useState("0")

    const [state, setState] = React.useState(false);

    const handleSwitchChange=(e)=>{
        setState(e.target.checked);
    }
    
    React.useEffect(()=>{
        const obtenerDatos=async()=>{
            
        try{
        const leyendoDBOutConfi=async(mac,canid,nameUid)=>{
            try {
                    const addr=`${usuario.username}/infoDevices/${mac}/${canid}/configModule`
                    const data=await db.collection(addr).doc(ide).get();
                    
                    
                    const actualItem={id: data.id, ...data.data()}
                    localStorage.setItem(nameUid,JSON.stringify(data.data().item))
                    console.log(actualItem)
                    //setNode(actualItem)

                    //setUid(actualItem.id)//uid de la base de datos
                    setComp(actualItem.item)//Toda la informacion del componente

                    
            } catch (error) {
                console.log(error)
            }
        }

        const arrayDeCadenas = ide.split("@");
        const mac=arrayDeCadenas[0]
        const canid=arrayDeCadenas[1]
        const nameUid=`Config@${ide}`
        
        setUid(ide)
        setNodeNameCache(nameUid)

            if(localStorage.getItem(nameUid)){
                console.log("Leyendo desde cache")
                setComp(JSON.parse(localStorage.getItem(nameUid)))
            }else{
                console.log("Leyendo desde base de datos")
                leyendoDBOutConfi(mac,canid,nameUid);
            }

         }catch(error){
           console.log(error)
         }
         
       }
       obtenerDatos()
      
         
    },[usuario.username,ide]);

    const editar=async(e)=>{
        e.preventDefault()
        console.log("object")
        
        if(!comp.name.trim()){
          setError("Ingrese Nombre")
          return
        }
        setError(null)
        setOpenWindow(true)
        
    }

    const onFallback=React.useCallback((e)=>{
        
        setComp({...comp,fallback:e.value})
    },[comp])
    
    const onCurrent=React.useCallback((e)=>{
        setComp({...comp,ifCurrent:e.value})
        console.log("Entre a OnCurrent")
        console.log("Esto es comp:", comp);
        //setIfCurrent()
    },[comp])
    
    
    const onActionCurrent=React.useCallback((e)=>{
        setComp({...comp,actionCurrent:e.value})
        //setActionCurrent(e.value)
    },[comp])

    const onType=React.useCallback((e)=>{
        if(e.value==="0"){

            setComp({...comp,data1:TogglesDataDefault.fan.data1,
                             data2:TogglesDataDefault.fan.data2,
                             data3:TogglesDataDefault.fan.data3,
                             type:e.value
                            })

        }

        else if(e.value==="1"){
        setComp({...comp,   data1:TogglesDataDefault.extractor.data1,
                            data2:TogglesDataDefault.extractor.data2,
                            data3:TogglesDataDefault.extractor.data3,
                            type:e.value
                        })

        }
        else if(e.value==="2"){// Inicializar datos
            setComp(
                {...comp,
                    data1:TogglesDataDefault.humnidificador.data1,
                    data2:TogglesDataDefault.humnidificador.data2,
                    data3:TogglesDataDefault.humnidificador.data3,
                    type:e.value
                }
            )


        }
        else if (e.value==="3"){
            setComp(
                {...comp,
                    data1:TogglesDataDefault.leds.data1,
                    data2:TogglesDataDefault.leds.data2,
                    data3:TogglesDataDefault.leds.data3,
                    type:e.value
                }
            )
        }

        else if (e.value==="4"){
            setComp(
                {...comp,
                    data1:TogglesDataDefault.bomba.data1,
                    data2:TogglesDataDefault.bomba.data2,
                    data3:TogglesDataDefault.bomba.data3,
                    type:e.value
                }
            )
        }

        else if(e.value==="5"){// Inicializar datos
            setComp(
                {...comp,
                    data1:TogglesDataDefault.always.data1,
                    data2:TogglesDataDefault.always.data2,
                    data3:TogglesDataDefault.always.data3,
                    type:e.value
                }
            )
        }
        
        
    },[comp])

    const onAlways=React.useCallback((e)=>{
        console.log(e)
        const data1=comp.data1;
        data1[0]=e.value;
        
        setComp(
            {...comp,
                data1:data1,
                data2:comp.data2,
                data3:comp.data3
            }
        )
        

    },[comp]);

    const updateDrop=(e)=>{
        //const dataY=e
        //console.log("e",data123)
        //console.log("Odata",e)
        
            setComp(
                {...comp,
                    data1:e.data1,
                    data2:e.data2,
                    data3:comp.data3
                }
            )
        console.log(e)
        
        setDesactivar(false)
        setColor("Green")
        setColor2("Blue")
        console.log("dropfirst",e)
    }
    const editGraph=()=>{
        setDesactivar(true)
        setColor("Orange")
        setColor2("Red")
    }

    const startAt=(e)=>{
        //setIfCurrent(e.value)
        //console.log(e.value)
        const edit=comp.data2;
        edit[0]=e.value;

        setComp(
            {...comp,
                data1:comp.data1,
                data2:edit,
                data3:comp.data3
            }
        )
    }

    const handdleCuantasHoras=React.useCallback((e)=>{
        //setCuantasHoras(e.value)
        setComp({...comp,cuantasHoras: e.value})
    },[comp])
 
    const handdleHoraInicio=React.useCallback((e)=>{
        setComp(prevValue => ({...prevValue, horaInicio:e.value}))
        //console.log("Entre a HoraIncio")
        //setHoraInicio(e.value)
    },[comp])

    /* const handdleHoraInicio=(e)=>{
        setComp(prevValue => ({...prevValue, horaInicio:e.value}))
        //console.log("Entre a HoraIncio")
        //console.log("HoraIncio => comp",comp);
        //setHoraInicio(e.value)
    } */

    const handdleData30=React.useCallback((e)=>{
        const edit=comp.data3;
        edit[0]=e.value;
        //console.log(edit)
        setComp(
            {...comp,
                data1:comp.data1,
                data2:comp.data2,
                data3:edit
            }
        )
    },[comp])

    const handdleData31=(e)=>{
        const edit=comp.data3;
        edit[1]=e.value;

        setComp(
            {...comp,
                data1:comp.data1,
                data2:comp.data2,
                data3:edit
            }
        )
    }

 
    const NumberD0_5=(e)=>{
        const edit=comp.data1;
        
        const id=e.id;
        const arrayDeCadenas = id.split("@")
        const ide=arrayDeCadenas[1];
        
        edit[ide]=e.value;

        setComp(
            {...comp,
                data1:edit,
                data2:comp.data2,
                data3:comp.data3
            }
        )
    }

    

    const TypeConfi=()=>{
        //console.log("dsfdsfdesfsd")
        //if(type==="0"){return (<ConfigFan data={props}/>)}
       
        const data123={
                        data1:comp.data1,
                        data2:comp.data2,
                        data3:comp.data3
                       }
        
        if(comp.type==="0"){     return (
                                    <ConfigFan
                                    data={data123} 
                                    updateDrop={updateDrop}
                                    click={editGraph}
                                    name="Ventilador"
                                    color={color}
                                    ytext="Minutos ON/OFF de Día"
                                    xtext="Semanas"
                                    head="Fan"
                                    seriesName="Minutos 'ON'/Semana en el Día"
                                    desactivar={desactivar}
                                    horaInicio={comp.horaInicio}
                                    handdleHoraInicio={handdleHoraInicio}

                                    cuantasHoras={comp.cuantasHoras}
                                    handdleCuantasHoras={handdleCuantasHoras}

                                    color2={color2}
                                    seriesName2="Minutos 'OFF'/Semana en el Día"

                                    handdleData30={handdleData30}
                                    handdleData31={handdleData31}
                                    />)
        }

        else if(comp.type==="1")     {     return (
                                      <ConfigExtractor 
                                      data={data123}
                                      NumberD0_5={NumberD0_5} 

                                      horaInicio={comp.horaInicio}
                                      handdleHoraInicio={handdleHoraInicio}

                                      cuantasHoras={comp.cuantasHoras}
                                      handdleCuantasHoras={handdleCuantasHoras}
                                      />
                                )}
        
        else if(comp.type==="2"){     return (
                                <ConfigHumni 
                                data={data123} 
                                updateDrop={updateDrop}
                                click={editGraph}
                                name="Humnidificador"
                                color={color}
                                ytext="Humedad %"
                                xtext="Semanas"
                                head="Huminidificador"
                                seriesName="Humedad por semana"

                                desactivar={desactivar}
                                horaInicio={comp.horaInicio}
                                handdleHoraInicio={handdleHoraInicio}

                                cuantasHoras={comp.cuantasHoras}
                                handdleCuantasHoras={handdleCuantasHoras}

                                />)
                            }
        
        else if(comp.type==="3"){return (
                                <ConfigLeds1 
                                data={data123} 
                                updateDrop={updateDrop}
                                click={editGraph}
                                startAt={startAt}
                                //name="Leds"
                                name="Temporizador Único"
                                desactivar={desactivar}
                                color={color}
                                switchState={state}
                                handleSwitchChange={handleSwitchChange}
                                //ytext= {usuario.username === "ExpoCeresGapyIO" ? "Minutos por Semana" : "Horas de Luz"}
                                ytext= {usuario.username === "ExpoCeresGapyIO" ? "Minutos por Semana" : "Horas encendido"}
                                //ytext= {state ? "Minutos por Semana" : "Horas encendido"}
                                xtext="Semanas"
                                //head="Leds"
                                head="Control Semanal"
                                //seriesName= {usuario.username === "ExpoCeresGapyIO" ? "Minutos encendido por Semana" : "Horas de luz por semana"}
                                seriesName= {usuario.username === "ExpoCeresGapyIO" ? "Minutos encendido por Semana" : "Horas encendido por semana"}
                                //seriesName= {state ? "Minutos encendido por Semana" : "Horas encendido por semana"}
                                />)
                            }
        
       
        else if(comp.type==="4"){return (
                                <ConfigPumbAC 
                                data={data123}
                                NumberD0_5={NumberD0_5} 

                                horaInicio={comp.horaInicio}
                                handdleHoraInicio={handdleHoraInicio}

                                cuantasHoras={comp.cuantasHoras}
                                handdleCuantasHoras={handdleCuantasHoras}
                                />
                                
                            )}
        else if(comp.type==="5"){return (
                                <ConfigAlways 
                                data={data123} 
                                handleToggle={onAlways}
                                />)
                            }
        else {return (<h3>Otro</h3>)}
    }


    
    const agreeDialog=async()=>{//Aun no guarda solo manda a cloudfunctions para mandar a IOT
        //const item={...node, fecha:Date.now(),msMqtt:codifyRules(node.arrayRules,node.canid)}
        setLoading(true)
        
        const item={
            msMqtt:codifyRules(comp),
            mac:comp.mac,
            action:actionLink,
            fecha:Date.now(),
            uid:nodeNameCache
        }
        
        
        try {
            const addr=`${usuario.username}/infoDevices/${comp.mac}/${comp.canid}/sendConfigModule`
            await db.collection(addr).doc("sendConfig").set({item})
            setOpenWindow(false)//Solo abre la ventana
            
        } catch (error) {
        console.log(error)
        } 
    }


    


    
    /* Notifica cuando se a terminado de configurar y cambia la bandera, loading permite modificar o cambiar*/ 
    React.useEffect(()=>{

        // const addr = usuario.username+'/infoDevices/'+comp.mac+'/'+comp.canid+'/fromModule/configOK';
        // const unsubscribe = db.doc(addr).onSnapshot(snapshot => {
        //     const data = snapshot.data();
        //     if(data){
        //         if (data.accion === 4 && data.act === 'recOK') {
        //         handleAlert(true,"success")
        //         setProgressFlag(valorAnterior => valorAnterior ? false : true)
        //         setCompleteManual(false) 
        //         const items = {
        //             accion: 0,
        //             act: "-"
        //         }
        //         clearConfigOk(addr,items)           
        //         }
        //     }
        // })

        const isTheUpdated=async()=>{
            const addr1=`${usuario.username}/infoDevices/${comp.mac}/${comp.canid}/sendConfigModule`
            const data=await (await db.collection(addr1).doc("sendConfig").get()).data()
            if(data.item.uid!==nodeNameCache){return false}
            else {return true}
        }
        
        const ChangeFireStore= async() => {
            const add=usuario.username+'/infoDevices/'+comp.mac+'/'+comp.canid+'/fromModule';
            
            await db.collection(add).where('act', '==', 'recOK')
            .onSnapshot(querySnapshot => {
                querySnapshot.docChanges().forEach(async (change) => {
                    
                if (change.type === 'modified') {
                    const breaking=await isTheUpdated()
                    if(breaking===false){return}

                    setLoading(false)//Una ves que llega el mensaje desactiva el bloqueo, el loading
                    setOpenAlert({
                        open:true,
                        type:"success",
                        tittle:"Configuración Exitosa",
                        txt:"El modulo se configuro correctamente",
                    })
                    const dataTime=change.doc.data().time
                    //console.log("object")                    

                    /*
                    const item={...comp,   fecha:Date.now(),msMqtt:codifyRules(comp)}
                    const addr=`${usuario.username}/infoDevices/${comp.mac}/${comp.canid}/configModule`
                    await db.collection(addr).doc(uid).update({item})
                    localStorage.setItem(nodeNameCache,JSON.stringify(item))
                    setComp(item)
                    */
                    
                    
                    setDateSnap(dataTime)

                }
                
                });
            });
        }
        if(comp && usuario.username){
            ChangeFireStore();
        }
        
    //},[comp,usuario.username,nodeNameCache,dateSnap])
    },[usuario.username,nodeNameCache])


    React.useEffect(()=>{
        const savingDB=async()=>{
            
            
            const item={...comp,   fecha:Date.now(),msMqtt:codifyRules(comp)}
            const addr=`${usuario.username}/infoDevices/${comp.mac}/${comp.canid}/configModule`
            
            await db.collection(addr).doc(uid).update({item})
            localStorage.setItem(nodeNameCache,JSON.stringify(item))
            setComp(item)            
            

            console.log("GuardandoDB",comp,nodeNameCache,uid,usuario.username)
            
        }

        if(dateSnap!=="0"){
            savingDB()
            setTimeout(() => {
                const indexName = parseInt(comp.outid)
                updateNames(indexName, comp.name)
              }, 1000);
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dateSnap]) 


    
    






    /* Notifica si Hubo un error o el mensaje cuando se envio al IoT*/ 
    React.useEffect(()=>{
        const ChangeFireStore= async() => {
            const add=usuario.username+'/logs/'+comp.mac;
            
            await db.collection(add).where('act', '==', 'sendConfigModule')
            .onSnapshot(querySnapshot => {
                querySnapshot.docChanges().forEach(change => {
                    
                if (change.type === 'modified') {
                    //console.log()
                    const data=change.doc.data()
                    if(data.action!==actionLink){return} 
                    if(data.uid!==nodeNameCache){return}//si este uid no fue el que se actualizo no cambia parametros
                    let alertjs={}
                    switch(data.state){
                        case "Error":
                            alertjs={
                                open:true,
                                type:"error",
                                tittle:"Error",
                                txt:"Hubo un error al enviar: Verifique la conexión a internet de GAPY-Master",
                            }
                            break;
                        case "Info":
                            alertjs={
                                open:true,
                                type:"info",
                                tittle:"Info",
                                txt:"Espere a que se configure correctamente GAPY-MASTER",
                            }
                            break;
                        default:
                            alertjs=defaultOpenAlert;
                            break;
                    }
                    setOpenAlert(alertjs)
                }
                
                });
            });
        }
        
        if(comp && usuario.username){
            ChangeFireStore();
        }
    },[comp,usuario.username,setOpenAlert,nodeNameCache])


    const close=(e)=>{
        //console.log(e)
        if(openAlert.type==="error"){
            setLoading(false)
        }
        setOpenAlert(defaultOpenAlert)
    }
    

    return (
        comp && 
        <div className="container ">
            <form onSubmit={editar}>
                    
                <div className="row">
                    
                    <div className="col-lg-4 col-md-12 mt-3">

                        <ul className="list-group">
                            <li className="list-group-item" style={{background:backGroundList}}>
                                <h3 style={{ color: "white" }}>Outs</h3>
                                
                            </li>
                    
                            <li className="list-group-item" >

                                <div className="row">
                                    <div className="col-5">
                                    Name: 
                                    </div>
                                    <div className="col-7">
                                        <input
                                        type="text"
                                        placeholder="Ingrese Nombre"
                                        className="form-control mb-2"
                                        onChange={e=> setComp({...comp,name:e.target.value})}
                                        value={comp.name}
                                        disabled={desactivar}
                                        ></input>
                                    
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-5">
                                    Out: 
                                    </div>
                                    <div className="col-7">
                                    {comp.outid}
                                    </div>
                                </div>
                                

                                <div className="row">
                                    <div className="col-5">
                                    MAC: 
                                    </div>
                                    <div className="col-7">
                                    {comp.mac}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-5">
                                        CAN: 
                                    </div>
                                    <div className="col-7">
                                        {comp.canid}
                                    </div>
                                </div>

                                    {
                                        comp.fecha&&(
                                            <div className="row">
                                                <div className="col-5">
                                                    Last Update: 
                                                </div>
                                                <div className="col-7">
                                                {moment(comp.fecha).format('llll')}
                                                </div>
                                            </div>
                                            
                                        )
                                    }

                                

                                

                            </li>
                        </ul>
                        
                        <CurretOffsets 
                        ide={ide} 
                        username={usuario.username} 
                        canId={comp.canid} 
                        mac={comp.mac} 
                        setOpenAlert={setOpenAlert} />
                    </div>
                    
                    
                    

                    <div className="col-lg-8 mt-3">
                            <li className="list-group-item" style={{background:backGroundList}}>
                                <h4 style={{ color: "white" }}>Configuración - {comp.name}</h4>
                            </li>
                        <ul className="list-group">
                            <li className="list-group-item" >

                                <div className="row">
                                    <div className="col-6">
                                    <h5>Corriente </h5>
                                    </div>
                                    <div className="col-6">
                                    <h5> Accion de emergencia</h5>
                                    </div>
                                </div>

                                
                                <div className="row">
                                    <div className="col-6">
                                        <InputNumber
                                        id="IC"
                                        label="Sí Valor"
                                        value={comp.ifCurrent}
                                        handleToggle={onCurrent}
                                        desactivar={desactivar}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <TresSelect
                                            id="ICA"
                                            label="Acción"
                                            value={comp.actionCurrent}
                                            handleToggle={onActionCurrent}
                                            desactivar={desactivar}
                                        />
                                    </div>
                                </div>
                                

                                <TresSelect
                                    id="FB"
                                    label="Fallback"
                                    value={comp.fallback}
                                    handleToggle={onFallback}
                                    desactivar={desactivar}
                                />
                                
                               
                        
                                <SelectType
                                    id="0"
                                    label="Tipo de Salida"
                                    value={comp.type}
                                    handleToggle={onType}
                                    desactivar={desactivar}
                                />

                                <TypeConfi/>

                                {//como operador ternario
                                desactivar && (
                                    <div className="col-12 mt-3">
                                    <div className="alert alert-warning alert-block">
                                        Mueva uno o mas puntos en la gráfica
                                    </div>

                                    </div>
                                    
                                )
                                }

                            </li>
                        </ul>
                        
                    </div>
                </div>

                <div className="row">
                        {//como operador ternario
                         error && (
                             <div className="col-lg-12 mt-3">
                                <div className="alert alert-danger alert-block">
                                 {error}
                                </div>

                             </div>
                             
                         )
                         }
                </div>  
                <UpdatingButton
                    type="submit" 
                    disabled={loading}
                />
                

            </form>

            <DialogConfirm
                open={openWindow}
                handleClose={()=>setOpenWindow(false)}
                agreeDialog={agreeDialog}
                title={"¿Está seguro que quiere continuar con esta operación?"}
                text={
                "Permite realizar configuraciones de la salidas o entradas del Nodo. Verifique que el Nodo que desea configurar este conectado através del GAPY-BUS"}

            />

            
            <TransitionAlerts
            data={openAlert}
            onClick={close}
            />



        </div>
                
    )
}

export default ConfigOutAC
