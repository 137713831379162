import React, { useContext, useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Divider,
  Typography,
  CircularProgress,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tabs,
  Tab,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { UserContext } from '../../../context/UserProvider';
import { db } from '../../../config/firebase';
import { CloudUpload, Edit} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { MasterModuleForm } from './MasterModuleForm';
import { GatewayLoraForm } from './GatewayLoraForm';
import { ConsultJapy } from './ConsultJapy';
import { HistoricalChats } from './HistoricalChats';


const useStyles = makeStyles(theme => ({
  formField: {
    margin: theme.spacing(1), // Agrega espaciado uniforme entre campos
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0.5), // Menos espacio en pantallas pequeñas
    },
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonProgress: {
    color: green[500],
     marginLeft: 12,
  },
  button: {
    margin: theme.spacing(1),
  },
}));


const ChatBotForm = () => {
  const classes = useStyles();
  const {dataMacCan,usuario,currentMac}=useContext(UserContext);

  const [dataValues, setDataValues] = useState({
    Humedad: '',
    Temperatura: '',
    'Sensor PAR': '',
    'Temperatura de la hoja': '',
    C02: '',
    ph: '',
    Ec: '',
    'Temperatura del Agua': '',
    "Temperatura ambiental": '',
    "Humedad ambiental": '',
    CO2: '',
    "Temperatura Sustrato": '',
    "Humedad Sustrato": '',
    "pH Sustrato": '',
    "EC Sustrato": '',
  })
  const [cropType, setCropType] = useState('');
  const [weekStage, setWeekStage] = useState('');
  const [growingSite, setGrowingSite] = useState('');
  const [loadingParameters, setLoadingParameters] = useState(false);
  const [savingDataCrop, setSavingDataCrop] = useState(false);
  const [savingSuccess, setSavingSuccess] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [deviceType, setDeviceType] = useState("master");
  const [gatewayExists, setGatewayExists] = useState(false);
  const [synchronized, setSynchronized] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSincronizar = async () => {
    setLoadingParameters(true);
    const formData = {
      username: usuario.username,
      //mac: dataMacCan[0].mac,
      mac: currentMac,
      deviceType: deviceType
    }
    try{
      const dataToSend = JSON.stringify(formData);
      const response = await fetch('https://us-central1-gapy-c999c.cloudfunctions.net/getDataToFirebase', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: dataToSend,
      });
      const jsonData = await response.json();
      setDataValues(jsonData);
      setSynchronized(true);
      
    } catch(error) {
      console.error('Error:', error);
    }
    setLoadingParameters(false);
  };

  const handleCropType = (e) =>{
    const {value} = e.target;
    setCropType(value);
  }

  const handleWeeksStage = (e) =>{
    const {value} = e.target;
    setWeekStage(value);
  }

  const handleGrowingSite = (e) =>{
    const {value} = e.target;
    setGrowingSite(value);
  }

  const handleDeviceChange = (e) => {
    setDeviceType(e.target.value);
  }

  const handleEdit = () => {
    
    if(editFlag === false){
      setEditFlag(!editFlag)
      setSavingSuccess(false);
    } else if(editFlag){
      const dataToSave = {
        crop: cropType,
        cultivation_stage: weekStage,
        cultivation_location: growingSite,
      }
  
      const savingDB = async (mac) => {
        try{
          
          const data = { ...dataToSave, fecha: Date.now() }
          const addr = `${usuario.username}/infoDevices/${mac}/1/cropInfo`;
          const docRef = db.collection(addr).doc('currentCrop');
          await docRef.update({ data });
          
          // await db.collection(addr).doc().update({ item });
  
  
          //console.log("GuardandoDB", comp, nodeNameCache, uid, usuario.username)
        } catch (error) {
          console.error("Error al guardar el documento:", error);
        }
  
      }
      
      try {
        if (dataMacCan.length>0) { 
          setSavingDataCrop(true);
          // console.log("Entre en SavingDB")
          //savingDB(dataMacCan[0].mac);
          savingDB(currentMac);
          setSavingDataCrop(false);
          setEditFlag(false);
          setSavingSuccess(true);
        }
      } catch (error) {
        console.error("Error en la direccion MAC:", error);
      }
    }
    
  }


  useEffect(() => {
    const obtenerDatos=async()=>{
      try{
       
        if(dataMacCan.length>0){
          // console.log(dataMacCan[0].mac);

          const leerDatosDB = async (mac) => {
            try {
              const addr = `${usuario.username}/infoDevices/${mac}/1/cropInfo`;
              const docRef = db.collection(addr).doc('currentCrop');
              const docSnap = await docRef.get();
          
              if (docSnap.exists) {
                // console.log("Datos del documento:", docSnap.data());
                const dataCrop = docSnap.data();
                // console.log('Esto es DataCrop:', dataCrop.data);
                setCropType(dataCrop.data.crop);
                setWeekStage(dataCrop.data.cultivation_stage);
                setGrowingSite(dataCrop.data.cultivation_location);
              } else {
                // El documento no existe.
                console.log("No se encontró el documento!");
              }
            } catch (error) {
              console.error("Error al obtener el documento:", error);
            }
          };

          const leerDatosGateway = async () => {
            try {
              const addr = `${usuario.username}/loraDevices/nodes`;
              const docRef = db.collection(addr).doc('0');
              const docSnap = await docRef.get();
              setGatewayExists(docSnap.exists ? true : false);
          
            } catch (error) {
              console.error("Error al obtener el documento:", error);
            }
          };
          
          //leerDatosDB(dataMacCan[0].mac);
          leerDatosDB(currentMac);
          leerDatosGateway();

         
        }
                   
      
      }catch(error){
        console.log(error)
      }
    }
    obtenerDatos()
  }, [usuario.username,dataMacCan])

  return (
    <>
      <Grid 
      container  
      justifyContent="center"
      alignItems="center" >
      <Typography variant="h6" className={classes.title}>
        Datos del Cultivo
      </Typography>
      </Grid>
      
      <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              id="cultivo"
              label="¿Qué estás cultivando?"
              type="text"
              fullWidth
              value={cropType}
              variant="outlined"
              disabled={!editFlag}
              onChange={handleCropType}
            />
            
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              id="fasePlanta"
              label="Semana y fase de la planta"
              type="text"
              fullWidth
              value={weekStage}
              variant="outlined"
              disabled={!editFlag}
              onChange={handleWeeksStage}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              id="lugarCultivo"
              label="Lugar de Cultivo"
              type="text"
              fullWidth
              value={growingSite}
              variant="outlined"
              disabled={!editFlag}
              onChange={handleGrowingSite}
            />
          </Grid>            
          </Grid>

          <div>
            <Grid container justifyContent='flex-end' alignItems='flex-end'>
            {savingDataCrop && <CircularProgress size={30} className={classes.buttonProgress}/>}
            {savingSuccess && <Alert severity="success">¡Actualización exitosa!</Alert>}
            
            {/* {savingSuccess && <CheckCircle size={30} color='primary' />} */}
            
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              endIcon={editFlag ? <CloudUpload /> : <Edit />}
              onClick={handleEdit}
            >
              {editFlag ? 'Actualizar' : 'Editar'}
            </Button>
            
            </Grid>
          </div>

          <div style={{ marginTop: '15px' }}>
            <Divider className={classes.divider} />
          </div>
          

      <Grid 
      container  
      justifyContent="center"
      alignItems="center" >
      <Typography variant="h6" className={classes.title}>
        Parámetros Ambientales
      </Typography>
      </Grid>

      { gatewayExists && (
        <> 
        {/* <Grid 
        container  
        justifyContent="center"
        alignItems="center">
          <FormControl>
            <FormLabel>Módulo Gapy: </FormLabel>
            <Select value={deviceType} onChange={handleDeviceChange}>
              <MenuItem value={"master"}>Master</MenuItem>
              <MenuItem value={"gateway"}>Gateway</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}

        <Grid container  
        justifyContent="center"
        alignItems="center">
          <FormLabel>Módulo Gapy: </FormLabel>
          <RadioGroup row aria-label="position" name="position" defaultValue="top" value={deviceType} onChange={handleDeviceChange}>
            <FormControlLabel
              value="master"
              control={<Radio color="primary" />}
              label="Master"
              labelPlacement="top"
            />

            <FormControlLabel
              value="gateway"
              control={<Radio color="primary" />}
              label="Gateway"
              labelPlacement="top"
            />

          </RadioGroup>
        </Grid>
        </>


      )}

      <form className={classes.formField} noValidate>
        { deviceType === "master" && <MasterModuleForm parametersValues={dataValues} />}
        { deviceType === "gateway" && <GatewayLoraForm parametersValues={dataValues} />}
        
        {/* Botones y otros controles */}
        <div  style={{ marginTop: '15px' }}>
          <Grid container justifyContent='center' alignItems='center'>
            <Button variant="contained" color="primary" onClick={handleSincronizar} disabled={loadingParameters} >
              Sincronizar
            </Button>
            {loadingParameters && <CircularProgress size={30} className={classes.buttonProgress} />}
          </Grid>
          
          
        </div>

        <div style={{ marginTop: '15px' }}>
          <Divider className={classes.divider} />
        </div>
        
        <Tabs 
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}            
        >
          <Tab label="Consulta a Japy" />
          <Tab label="Historial de chats" />        
        </Tabs>

        <div hidden={tabValue !== 0}>
          <ConsultJapy 
          username={usuario.username}
          cropType={cropType}
          weekStage={weekStage}
          growingSite={growingSite}
          synchronized={synchronized}
          setSynchronized={setSynchronized}
          />
        </div>

        <div hidden={tabValue !== 1}>
          <HistoricalChats username={usuario.username} />
        </div>
      </form>
      </>
  );
}

export default ChatBotForm;