import React, { useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from '../../../../context/UserProvider';
import { Divider, Grid, Typography, makeStyles } from '@material-ui/core';
import HeadTile from './HeadTile';
import { db } from '../../../../config/firebase';

const useStyles = makeStyles((theme) => ({
  formField: {
    margin: theme.spacing(1), // Agrega espaciado uniforme entre campos
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0.5), // Menos espacio en pantallas pequeñas
    },
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  input: {
    // Ajusta el tamaño del input y otros estilos aquí
    width: '100%', // Hace que el input ocupe todo el ancho del contenedor
    padding: theme.spacing(1), // Usa el sistema de espaciado de Material-UI para el padding
    [theme.breakpoints.down('sm')]: {
      // Ajustes para pantallas pequeñas (dispositivos móviles)
      padding: theme.spacing(0.5),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 50,
    minHeight: 50,
  },
}));

export const NodeLoraTile = ({ col, data, onClickCrossButton, style }) => {
    const { usuario, dataMacCan } = useContext(UserContext);
    // const uid = "MF0B3B5B5AA8C@1@9@0";
    const uid = data.uid;
    const arrayDeCadenas = data.uid.split("@");
    const [nodeName, setNodeName] = useState( `Nodo ${arrayDeCadenas[2]}`);
    const [dataParameters, setDataParameters] = useState({
      co2_sustrato: "-",
      ec_sustrato: "-",
      hum_ambiental: "-",
      hum_sustrato: "-",
      ph_sustrato: "-",
      temp_ambiental: "-",
      temp_sustrato: "-"
    });
    const classes = useStyles();

    const useDocumentListener = (docPath) => {
      useEffect(() => {
        // Obtiene la referencia al documento
        const docRef = db.doc(docPath);
    
        // Se suscribe a los cambios del documento
        const unsubscribe = docRef.onSnapshot((docSnapshot) => {
          if (docSnapshot.exists) {
            // console.log("Datos actuales del documento: ", docSnapshot.data());
            // Aquí puedes manejar los datos recibidos
            // Por ejemplo, actualizar el estado de tu componente
            data = docSnapshot.data();
            setDataParameters(data);
            // console.log("Esto es data:", data);
          } else {
            console.log("¡No se encontró el documento!");
          }
        }, (err) => {
          console.error("Error al escuchar el documento: ", err);
        });
    
        // Función de limpieza que se ejecuta cuando el componente se desmonta
        return () => unsubscribe();
    
      }, [docPath]); // El efecto se vuelve a ejecutar si docPath cambia
    };

    const docPath = `${usuario.username}/loraDevices/nodes/${arrayDeCadenas[2]}`;

    useDocumentListener(docPath);
    const isMountedRef = useRef(null);


    useEffect(() => {
      isMountedRef.current = true; // Indicar que el componente está montado

      const obtenerDatos = async () => {
        try {
          
          const arrayDeCadenas = uid.split("@");
          // const mac = arrayDeCadenas[0]; 
          const nodeid = arrayDeCadenas[2];
          // const nameUid = `Config@${uid}`;
          // console.log(canid);
  
          const addr = `${usuario.username}/loraDevices/nodes`;
          const addr2 = `${usuario.username}/loraDevices/nodes/${nodeid}/configNode`;
  
          // if (localStorage.getItem(nameUid)) {
          //   console.log("Leyendo desde cache");
          //   setComp(JSON.parse(localStorage.getItem(nameUid)));
          // } else {
          //   console.log("Leyendo desde base de datos");
          //   leyendoDBSoil(nameUid, addr);
          // }
  
          const leerDatosDB = async () => {
            try {
              const docRef = db.collection(addr).doc(nodeid);
              const docSnap = await docRef.get();
          
              if (docSnap.exists) {
                // console.log("Datos del documento:", docSnap.data());
                const dataItem = docSnap.data();
                // Solo actualizar si el componente aún está montado
                if (isMountedRef.current) {
                  setDataParameters(dataItem);
                  //console.log(dataItem);
                }
                
              } else {
                // El documento no existe.
                if (isMountedRef.current) {
                  console.log("No se encontró datos Lora");
                }
                
              }

            } catch (error) {
              console.error("Error al obtener el documento:", error);
            }
          };

          const leerConfigNode = async () => {
            try {
              const docRef = db.collection(addr2).doc("renderData");
              const docSnap = await docRef.get();
          
              if (docSnap.exists) {
                // console.log("Datos del documento:", docSnap.data());
                const dataItem = docSnap.data();
                // Solo actualizar si el componente aún está montado
                if (isMountedRef.current) {
                  setNodeName(dataItem.item.nodeName);
                }
                
              } else {
                // El documento no existe.
                if (isMountedRef.current) {
                  console.log("No se encontró nombre del Nodo Lora");
                }
                
              }

            } catch (error) {
              console.error("Error al obtener el documento:", error);
            }
          };
          
          leerDatosDB();
          leerConfigNode();
        } catch (error) {
          console.log("obtenerDatos: error ", error);
        }
      };
      obtenerDatos();
      return () => {
        isMountedRef.current = false; // Indicar que el componente se ha desmontado
      };
    }, [uid, usuario.username]);

  return (

    <Grid 
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
    style={style}
    spacing={1}
    >
    {/* Encabezado (suponiendo que HeadTile es el encabezado de tu card) */}

      <Grid item xs={12}>
        <HeadTile
          name={nodeName}
          uid={uid}
          onClickCrossButton={onClickCrossButton}
          col={col}
        />
      </Grid>
      
      {/* Fila 1 */}
      <Grid item xs={6} container direction="column" justifyContent="center" alignItems="center" style={{ textAlign: 'center' }}>
      <Typography variant="subtitle2" gutterBottom>
        Temperatura Sustrato 
      </Typography>
        <h5>
          <span className="badge badge-dark">{dataParameters.temp_sustrato}</span>
        </h5>
      </Grid>

      <Grid item xs={6} container direction="column" style={{ textAlign: 'center' }}>
      <Typography variant="subtitle2" gutterBottom>
        Humedad Sustrato
      </Typography>
        <h5>
          <span className="badge badge-dark">{dataParameters.hum_sustrato}</span>
        </h5>
      </Grid>

      <Grid item xs={11}>
        <Divider className={classes.divider} />
      </Grid>

      {/* Fila 2 */}
      <Grid item xs={6} container direction="column" justifyContent="center" alignItems="center" style={{ textAlign: 'center' }}>
      <Typography variant="subtitle2" gutterBottom>
        EC Sustrato
      </Typography>
        <h5>
          <span className="badge badge-dark" >{dataParameters.ec_sustrato}</span>
        </h5>
      </Grid>

     
      <Grid item xs={6} container direction="column" style={{ textAlign: 'center' }}>
        {/* Contenido para la segunda fila */}
        <Typography variant="subtitle2" gutterBottom>
        pH Sustrato
        </Typography>
        <h5>
          <span className="badge badge-dark">{dataParameters.ph_sustrato}</span>
        </h5>
      </Grid>

      <Grid item xs={11}>
        <Divider className={classes.divider} />
      </Grid>

      {/* Fila 3 */}
      <Grid item xs={6} container direction="column" justifyContent="center" alignItems="center" style={{ textAlign: 'center' }}>
        {/* Contenido para la segunda fila */}
        <div>
        <Typography variant="subtitle2" gutterBottom>
        CO2 Ambiental
      </Typography>
      </div>
        <div>
        <h5>
          <span className="badge badge-dark">{dataParameters.co2_sustrato}</span>
        </h5>
        </div>
      </Grid>
      <Grid item xs={6} container direction="column" justifyContent="center"  style={{ textAlign: 'center' }}>
        {/* Contenido para la segunda fila */}
        <Typography variant="subtitle2" gutterBottom>
        Temperatura Ambiental
      </Typography>
        <h5>
          <span className="badge badge-dark">{dataParameters.temp_ambiental}</span>
        </h5>
      </Grid>

      <Grid item xs={11}>
        <Divider className={classes.divider} />
      </Grid>

      {/* Fila 4 */}
      <Grid item xs={6} container direction="column" justifyContent="center"  style={{ textAlign: 'center' }}>
        {/* Contenido para la tercera fila */}
        <Typography variant="subtitle2" gutterBottom>
        Humedad Ambiental
      </Typography>
        <h5>
          <span className="badge badge-dark">{dataParameters.hum_ambiental}</span>
        </h5>
      </Grid>

    </Grid>


  )
}

